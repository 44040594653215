import { Chip, Grid } from "@mui/material";
import {
  AIBoxContent,
  AIBoxTitle,
  AITableCardBox,
  AITableContentsBox,
  SECONDARY,
  TableFixedHeight,
} from "../../../styles/theme";
import {
  ActionItemDetailInfo,
  BasicInfo,
  ManagerInfo,
  OrganizationInfo,
} from "../../../system/types/ActionItem";
import { CustomText } from "../../../styles/CustomText";
import EditIcon from "@mui/icons-material/Edit";
import { Gray300, Gray400, Gray700 } from "../../../styles/Colors";
import moment from "moment";
import { useState } from "react";
import ActionEdit from "../ActionEdit";
import uuid from "react-uuid";

interface AIDetailInfoProps {
  data: ActionItemDetailInfo;
  refreshData: () => void;
  teamData: OrganizationInfo[];
  hasPermission: boolean;
}
function AIDetailInfo({
  data,
  refreshData,
  teamData,
  hasPermission,
}: AIDetailInfoProps) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const primaryManagerList: ManagerInfo[] = data.managers.filter(
    (x: ManagerInfo) => x.isPrimaryManager
  );

  const managerList: ManagerInfo[] = data.managers.filter(
    (x: ManagerInfo) => !x.isPrimaryManager
  );
  return (
    <AITableCardBox mt="10px">
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        padding="10px"
      >
        <CustomText type="subtitle" bold600 color={Gray700}>
          A/I(Action Item) 내용
        </CustomText>
        {hasPermission && (
          <Chip
            variant="outlined"
            color="warning"
            size="small"
            label="수정"
            icon={<EditIcon />}
            onClick={() => {
              setOpen(true);
            }}
            style={{ cursor: "pointer", marginRight: "5px" }}
          />
        )}
      </Grid>
      <AITableContentsBox container item xs={12}>
        <TableFixedHeight
          mb="5px"
          container
          item
          xs={12}
          sm={6}
          alignItems="center"
        >
          <AIBoxTitle container item xs={4} pr="10px" justifyContent="right">
            <CustomText type="subtitle" bold400 lineSpace color={Gray400}>
              A/I발생 회의명
            </CustomText>
          </AIBoxTitle>
          <AIBoxContent item xs={8} pl="10px">
            <CustomText type="subtitle" bold400 color={Gray700}>
              {data.triggeringReport.title}
            </CustomText>
          </AIBoxContent>
        </TableFixedHeight>
        <TableFixedHeight
          mb="5px"
          container
          item
          xs={12}
          sm={6}
          alignItems="center"
        >
          <AIBoxTitle container item xs={4} pr="10px" justifyContent="right">
            <CustomText type="subtitle" bold400 lineSpace color={Gray400}>
              A/I발생일
            </CustomText>
          </AIBoxTitle>
          <AIBoxContent item xs={8} pl="10px">
            <CustomText type="subtitle" bold400 color={Gray700}>
              {moment(data.triggeringReport.reportDate).format("YYYY-MM-DD")}
            </CustomText>
          </AIBoxContent>
        </TableFixedHeight>
        <TableFixedHeight mb="5px" container item xs={12} alignItems="center">
          <AIBoxTitle container item xs={2} pr="10px" justifyContent="right">
            <CustomText type="subtitle" bold400 lineSpace color={Gray400}>
              제목
            </CustomText>
          </AIBoxTitle>
          <AIBoxContent item xs={10} pl="10px">
            <CustomText type="subtitle" bold600 color={Gray700}>
              {data.title}
            </CustomText>
          </AIBoxContent>
        </TableFixedHeight>
        <TableFixedHeight
          mb="5px"
          container
          item
          xs={12}
          sm={6}
          alignItems="center"
        >
          <AIBoxTitle container item xs={4} pr="10px" justifyContent="right">
            <CustomText type="subtitle" bold400 lineSpace color={Gray400}>
              카테고리
            </CustomText>
          </AIBoxTitle>
          <AIBoxContent item xs={8} pl="10px">
            <CustomText type="subtitle" bold600 color={Gray700}>
              {data.categories?.length > 0 &&
                data.categories.map((dt: BasicInfo) => dt.name).join(", ")}
            </CustomText>
          </AIBoxContent>
        </TableFixedHeight>
        <TableFixedHeight
          mb="5px"
          container
          item
          xs={12}
          sm={6}
          alignItems="center"
        >
          <AIBoxTitle container item xs={4} pr="10px" justifyContent="right">
            <CustomText type="subtitle" bold400 lineSpace color={Gray400}>
              상태
            </CustomText>
          </AIBoxTitle>
          <AIBoxContent item xs={8} pl="10px">
            <CustomText type="subtitle" bold600 color={Gray700}>
              {data.status}
            </CustomText>
          </AIBoxContent>
        </TableFixedHeight>
        <TableFixedHeight mb="5px" container item xs={12} alignItems="center">
          <AIBoxTitle
            container
            item
            xs={2}
            pr="10px"
            justifyContent="right"
            alignItems="center"
          >
            <CustomText type="subtitle" bold400 lineSpace color={Gray400}>
              내용
            </CustomText>
          </AIBoxTitle>
          <AIBoxContent item xs={10} pl="10px">
            <CustomText
              type="subtitle"
              bold400
              color={Gray700}
              style={{
                whiteSpace: "pre-line",
                marginBottom: "5px",
                border: `1px solid ${Gray300}`,
                borderRadius: "8px",
                padding: "5px",
                width: "90%",
              }}
            >
              {data.content}
            </CustomText>
          </AIBoxContent>
        </TableFixedHeight>
        <TableFixedHeight
          mb="5px"
          container
          item
          xs={12}
          sm={6}
          alignItems="center"
        >
          <AIBoxTitle container item xs={4} pr="10px" justifyContent="right">
            <CustomText type="subtitle" bold400 lineSpace color={Gray400}>
              A/I 수행 주관 부서
            </CustomText>
          </AIBoxTitle>
          <AIBoxContent item xs={8} pl="10px">
            <CustomText type="subtitle" bold400 color={Gray700}>
              {data.organizationUnit.name}
            </CustomText>
          </AIBoxContent>
        </TableFixedHeight>
        <TableFixedHeight
          mb="5px"
          container
          item
          xs={12}
          sm={6}
          alignItems="center"
        >
          <AIBoxTitle container item xs={4} pr="10px" justifyContent="right">
            <CustomText type="subtitle" bold400 lineSpace color={Gray400}>
              A/I 담당자
            </CustomText>
          </AIBoxTitle>
          <AIBoxContent item xs={8} pl="10px">
            {primaryManagerList.map((dt, index) => {
              return (
                <Chip
                  key={uuid()}
                  style={{
                    backgroundColor: "#1b4c97",
                    color: "white",
                    marginRight: "2px",
                  }}
                  label={
                    "정) " + dt.manager.organizationName + " " + dt.manager.name
                  }
                  size="small"
                />
              );
            })}

            {managerList.map((dt, index) => {
              return (
                <Chip
                  key={uuid()}
                  style={{
                    backgroundColor: SECONDARY,
                    color: "white",
                    marginRight: "2px",
                  }}
                  label={
                    "부) " + dt.manager.organizationName + " " + dt.manager.name
                  }
                  size="small"
                />
              );
            })}
          </AIBoxContent>
        </TableFixedHeight>
        <TableFixedHeight
          mb="5px"
          container
          item
          xs={12}
          sm={6}
          alignItems="center"
        >
          <AIBoxTitle container item xs={4} pr="10px" justifyContent="right">
            <CustomText type="subtitle" bold400 lineSpace color={Gray400}>
              완료 예정일
            </CustomText>
          </AIBoxTitle>
          <AIBoxContent item xs={8} pl="10px">
            <CustomText type="subtitle" bold400 color={Gray700}>
              {moment(data.dueDate).format("YYYY-MM-DD")}
            </CustomText>
          </AIBoxContent>
        </TableFixedHeight>
        <TableFixedHeight
          mb="5px"
          container
          item
          xs={12}
          sm={6}
          alignItems="center"
        >
          <AIBoxTitle container item xs={4} pr="10px" justifyContent="right">
            <CustomText type="subtitle" bold400 lineSpace color={Gray400}>
              우선순위
            </CustomText>
          </AIBoxTitle>
          <AIBoxContent item xs={8} pl="10px">
            <CustomText type="subtitle" bold400 color={Gray700}>
              {data.priority}
            </CustomText>
          </AIBoxContent>
        </TableFixedHeight>
        <TableFixedHeight
          mb="5px"
          container
          item
          xs={12}
          sm={6}
          alignItems="center"
        >
          <AIBoxTitle container item xs={4} pr="10px" justifyContent="right">
            <CustomText type="subtitle" bold400 lineSpace color={Gray400}>
              비밀글
            </CustomText>
          </AIBoxTitle>
          <AIBoxContent item xs={8} pl="10px">
            <CustomText type="subtitle" bold400 color={Gray700}>
              {data.isSecret ? "설정" : "설정 안 함"}
            </CustomText>
          </AIBoxContent>
        </TableFixedHeight>

        <TableFixedHeight
          mb="5px"
          container
          item
          xs={12}
          sm={6}
          alignItems="center"
        >
          <AIBoxTitle container item xs={4} pr="10px" justifyContent="right">
            <CustomText type="subtitle" bold400 lineSpace color={Gray400}>
              긴급
            </CustomText>
          </AIBoxTitle>
          <AIBoxContent item xs={8} pl="10px">
            <CustomText type="subtitle" bold400 color={Gray700}>
              {data.isUrgent ? "설정" : "설정 안 함"}
            </CustomText>
          </AIBoxContent>
        </TableFixedHeight>
        <TableFixedHeight
          mb="5px"
          container
          item
          xs={12}
          sm={6}
          alignItems="center"
        >
          <AIBoxTitle container item xs={4} pr="10px" justifyContent="right">
            <CustomText type="subtitle" bold400 lineSpace color={Gray400}>
              작성자
            </CustomText>
          </AIBoxTitle>
          <AIBoxContent item xs={8} pl="10px">
            <CustomText type="subtitle" bold400 color={Gray700}>
              {data.createBy}
            </CustomText>
          </AIBoxContent>
        </TableFixedHeight>
        <TableFixedHeight
          mb="5px"
          container
          item
          xs={12}
          sm={6}
          alignItems="center"
        >
          <AIBoxTitle container item xs={4} pr="10px" justifyContent="right">
            <CustomText type="subtitle" bold400 lineSpace color={Gray400}>
              작성일
            </CustomText>
          </AIBoxTitle>
          <AIBoxContent item xs={8} pl="10px">
            <CustomText type="subtitle" bold400 color={Gray700}>
              {moment(data.createdAt).format("YYYY-MM-DD")}
            </CustomText>
          </AIBoxContent>
        </TableFixedHeight>
      </AITableContentsBox>

      <ActionEdit
        teamData={teamData}
        refreshData={refreshData}
        open={open}
        handleClose={handleClose}
        previousData={{
          status: data.status,
          title: data.title,
          content: data.content,
          parentId: data.parent?.id ? data.parent.id : null,
          managers: data.managers,
          priority: data.priority,
          isUrgent: data.isUrgent,
          isSecret: data.isSecret,
          dueDate: data.dueDate,
          categoryIds: data.categories.map((item) => item.id),
          triggeringReportId: data.triggeringReport.id
            ? data.triggeringReport.id
            : null,
          organizationUnitId: data.organizationUnit?.id
            ? data.organizationUnit.id
            : null,
        }}
        type="수정"
        organizationunitName={
          data.organizationUnit ? data.organizationUnit.name : null
        }
        id={data.id}
      />
    </AITableCardBox>
  );
}

export default AIDetailInfo;
